export const COLORS = {
  primaryPurple: '#1A053C',
  lightPurple: '#2A074C',
  lighterPurple: '#5A1A8C', 
  darkPurple: '#0A052C',
  lightGold: '#FFF2AC', 
  primaryGold: '#FFD700',
  darkGold: '#996D00',
  white: '#FFFFFF',
  black:'#000000',
  lightGray: '#D3D3D3', 
  gray: '#BDBDBD', 
  darkGray: '#4A4A4A', 
  darkRed: '#B22222', 
  lightRed: '#FFA0A0', 
  green: '#90EE90',
  darkGreen: '#006400',  
  red: '#FF0000',  
  primaryBeige: '#FAFAF8', 
  lightBeige: '#FEFEFC',
  darkBeige: '#F5F5F0', 
};

export const DarkTheme = {
  primaryBG: COLORS.primaryPurple,
  lightBG: COLORS.lightPurple,
  darkBG: COLORS.darkPurple,
  textColor: COLORS.white,
  primaryAI: COLORS.primaryGold,
  lightGray: COLORS.lightGray,
  gray: COLORS.gray,
  darkRed: COLORS.darkRed, 
  lightRed: COLORS.lightRed, 
  green: COLORS.green,
  red: COLORS.red,
  white: COLORS.white,
  dittoPurple: COLORS.primaryPurple,
};

export const LightTheme = {
  primaryBG: COLORS.primaryBeige,
  lightBG: COLORS.lightBeige, 
  darkBG: COLORS.darkBeige,  
  textColor: COLORS.black,
  primaryAI: COLORS.darkGold,
  lightGray: COLORS.darkGray,
  gray: COLORS.darkGray,
  darkRed: COLORS.darkRed, 
  lightRed: COLORS.lightRed, 
  green: COLORS.darkGreen,
  red: COLORS.red,
  white: COLORS.white,
  dittoPurple: COLORS.primaryPurple,
};

export default { COLORS, DarkTheme, LightTheme }