import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Message } from '../types';
import MessageComponent from './Message';
import { useAccount, useConfig } from 'wagmi';
import { sendTransaction } from '@wagmi/core';
import { API_BASE_URL, WS_CONNECTION_TYPE } from '../config';
import QRCode from 'qrcode';
import {LightTheme} from '../styles/colors';
import { IoMdArrowUp } from 'react-icons/io';

interface ChatProps {
  // sessionId and setSessionId removed from props
}

const Chat: React.FC<ChatProps> = () => {
  const { address } = useAccount();
  const config = useConfig();

  const [messages, setMessages] = useState<Message[]>([]);
  const [sampleMessages] = useState([
    "Send $1 to someone",
    "Send an onchain message",
    "Summarize my portfolio change",
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);

  const messageCounterRef = useRef(0);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const shouldScrollRef = useRef(true);

  const generateUniqueId = () => {
    messageCounterRef.current += 1;
    return `${Date.now()}-${messageCounterRef.current}`;
  };

  const updateUserState = useCallback(() => {
    if (websocket && websocket.readyState === WebSocket.OPEN && sessionId && address) {
      websocket.send(JSON.stringify({
        type: 'UPDATE_USER_STATE',
        payload: { 
          evmWalletAddress: address, 
          sessionId 
        }
      }));
    }
  }, [websocket, sessionId, address]);

  const handleSignAndSendTransactions = async (txObjectAndSimulations: any[], ws: WebSocket | null) => {
    if (!address) {
      throw new Error('Wallet not connected');
    }

    console.log(txObjectAndSimulations)

    const transactionHashes: string[] = [];

    for (const txObjectAndSimulation of txObjectAndSimulations) {
      const txObject = txObjectAndSimulation.txObject
      try {
        const transactionHash = await sendTransaction(config, {
          account: address,
          to: txObject.to,
          value: BigInt(txObject.value),
          data: txObject.data,
          chainId: txObject.chainId,
          gas: txObject.gasLimit ? BigInt(txObject.gasLimit) : undefined,
          gasPrice: txObject.gasPrice ? BigInt(txObject.gasPrice) : undefined,
          maxFeePerGas: txObject.maxFeePerGas ? BigInt(txObject.maxFeePerGas) : undefined,
          maxPriorityFeePerGas: txObject.maxPriorityFeePerGas ? BigInt(txObject.maxPriorityFeePerGas) : undefined,
        });

        console.log('Transaction sent:', transactionHash);
        transactionHashes.push(transactionHash);

    

      // Send all transaction hashes back to the backend
      ws?.send(JSON.stringify({
        type: 'SIGNED_AND_SENT_TRANSACTIONS',
        payload: {
          signedAndSentTxs: transactionHashes,
          sessionId: sessionId
        }
      }));

        // Update user state after successful transaction
        updateUserState();

      } catch (error) {
        console.error('Error in transaction process:', error);
        ws?.send(JSON.stringify({
          type: 'TRANSACTION_ERROR',
          payload: {
            error: (error as Error).message || 'Unknown error',
            sessionId: sessionId
          }
        }))
      }
    }
  };

  const generateAddressQRCode = async (wallet_address: string, asset_class: 'solana' | 'evm' | 'btc'): Promise<string> => {
    let uri: string;

    switch (asset_class) {
      case 'evm':
        uri = `ethereum:${wallet_address}`;
        break;
      case 'btc':
        uri = `bitcoin:${wallet_address}`;
        break;
      case 'solana':
        uri = `solana:${wallet_address}`;
        break;
      default:
        throw new Error('Unsupported asset class');
    }

    try {
      const qrCodeDataUrl = await QRCode.toDataURL(uri, {
        width: 1024,
        margin: 2,
        errorCorrectionLevel: 'H'
      });
      return qrCodeDataUrl;
    } catch (error) {
      console.error('Error generating QR code:', error);
      throw new Error('Failed to generate QR code');
    }
  };

  const handleWebSocketMessage = useCallback((event: MessageEvent, ws: WebSocket | null) => {
    const data = JSON.parse(event.data);
    
    switch (data.type) {
      case 'SESSION_ID':
        console.log('Received session ID:', data.payload);
        setSessionId(data.payload);
        break;
      case 'END':
        console.log('Received END message');
        setIsLoading(false);
        break;
      case 'SIGN_AND_SEND_REQUEST':
        console.log('Received sign and send request:', data.payload);
        handleSignAndSendTransactions(data.payload, ws);
        break;
      case 'BOT_MESSAGE':
        console.log('Adding new bot message:', data.payload);
        setMessages(prevMessages => [
          ...prevMessages,
          { id: generateUniqueId(), text: data.payload, sender: 'bot', timestamp: new Date() }
        ]);
        break;
      case 'TOOL_USE':
        console.log('Tool being used:', data.payload);
        setMessages(prevMessages => [
          ...prevMessages,
          { id: generateUniqueId(), text: data.payload, sender: 'bot', timestamp: new Date() }
        ]);
        break;
      case 'ERROR':
        console.error('Received error:', data.payload);
        setMessages(prevMessages => [
          ...prevMessages,
          { 
            id: generateUniqueId(), 
            text: `Error: ${data.payload}`, 
            sender: 'bot', 
            timestamp: new Date() 
          }
        ]);
        setIsLoading(false);
        break;
      case 'DISPLAY_QR_CODE':
        console.log('Received QR code request:', data.payload);
        handleQRCodeRequest(data.payload);
        break;
      case 'WALLET_HOLDINGS':
        console.log('Received wallet holdings:', data.payload);
        // do nothing
        break;
      default:
        console.warn('Unknown message type:', data.type);
    }
  }, [handleSignAndSendTransactions, setSessionId]);

  const handleQRCodeRequest = async (payload: { wallet_address: string, asset_class: 'solana' | 'evm' | 'btc' }) => {
    try {
      const qrCodeDataUrl = await generateAddressQRCode(payload.wallet_address, payload.asset_class);
      setMessages(prevMessages => [
        ...prevMessages,
        { 
          id: generateUniqueId(), 
          text: ``, 
          sender: 'bot', 
          timestamp: new Date(),
          qrCodeUrl: qrCodeDataUrl
        }
      ]);
    } catch (error) {
      console.error('Failed to generate QR code:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        { 
          id: generateUniqueId(), 
          text: 'Failed to generate QR code', 
          sender: 'bot', 
          timestamp: new Date() 
        }
      ]);
    }
  };

  const handleSampleMessageClick = (message: string) => {
    if (!isLoading && websocket && websocket.readyState === WebSocket.OPEN) {
      const userMessage: Message = {
        id: generateUniqueId(),
        text: message,
        sender: 'user',
        timestamp: new Date(),
      };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setIsLoading(true);

      websocket.send(JSON.stringify({
        type: 'USER_MESSAGE',
        payload: {text: message},
        sessionId: sessionId
      }));
    }
  };

  useEffect(() => {
    let ws: WebSocket | null = null;

    const connectWebSocket = () => {
      if (websocket && (websocket.readyState === WebSocket.OPEN || websocket.readyState === WebSocket.CONNECTING)) {
        return;
      }

      ws = new WebSocket(`${WS_CONNECTION_TYPE}://${API_BASE_URL.replace(/^https?:\/\//, '')}/api/chat/ws`);

      ws.onopen = () => {
        console.log('WebSocket connected');
        setWebsocket(ws);
      };

      ws.onmessage = (event) => handleWebSocketMessage(event, ws);

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = (event) => {
        console.log('WebSocket closed:', event.reason);
        setWebsocket(null);
        setTimeout(connectWebSocket, 5000);
      };
    };

    if (!websocket) {
      connectWebSocket();
    }

    return () => {
      // if (ws) {
      //   ws.close();
      // }
    };
  }, [websocket, handleWebSocketMessage]);

  const handleSend = () => {
    if (input.trim() && !isLoading && websocket && websocket.readyState === WebSocket.OPEN) {
      const userMessage: Message = {
        id: generateUniqueId(),
        text: input,
        sender: 'user',
        timestamp: new Date(),
      };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setInput('');
      setIsLoading(true);

      websocket.send(JSON.stringify({
        type: 'USER_MESSAGE',
        payload: {text: input},
        sessionId: sessionId
      }));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !isLoading) {
      handleSend();
    }
  };

  useEffect(() => {
    if (shouldScrollRef.current && messagesContainerRef.current) {
      const { scrollHeight, clientHeight } = messagesContainerRef.current;
      messagesContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, [messages]);

  const handleScroll = () => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      const atBottom = scrollHeight - scrollTop - clientHeight < 1;
      shouldScrollRef.current = atBottom;
    }
  };

  // Add a new useEffect to call updateUserState when address changes or websocket state changes
  useEffect(() => {
    updateUserState();
  }, [address, websocket, updateUserState]);

  return (
    <div  style={styles.chatWrapper}>
      <div style={styles.chatContainer}>
        <div 
          className="messages-container" 
          style={styles.messagesContainer} 
          ref={messagesContainerRef}
          onScroll={handleScroll}
        >
          {messages.length === 0 && (
            <div className="sample-messages" style={styles.sampleMessages}>
              <p style={styles.sampleMessagesTitle}>Here are something you can ask:</p>
              {sampleMessages.map((message, index) => (
                <button 
                  key={index} 
                  onClick={() => handleSampleMessageClick(message)}
                  style={styles.sampleMessageButton}
                  className="sample-message-button"
                >
                  {message}
                </button>
              ))}
            </div>
          )}
          {messages.map((message, index) => (
            <MessageComponent key={index} message={message} />
          ))}
          {isLoading && <div className="message bot" style={styles.loadingMessage}>Generating response...</div>}
        </div>
        <div className="input-container" style={styles.inputContainer}>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={isLoading ? "Waiting for response..." : "Type your message..."}
            onKeyDown={handleKeyPress}
            disabled={isLoading}
            style={{
              ...styles.inputField,
              ...(isLoading ? styles.inputFieldDisabled : {})
            }}
          />
          <button
            onClick={handleSend}
            disabled={isLoading}
            style={{
              ...styles.sendButton,
              ...(isLoading ? styles.sendButtonDisabled : {})
            }}
            aria-label="Send message"
          >
            <IoMdArrowUp size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  chatWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    height: '75vh',
    width: '60%',
    // maxWidth: '800px', // Add a max-width for larger screens
    backgroundColor: LightTheme.primaryBG,
    border: '1px solid #ccc',
    borderRadius: '12px',
    overflow: 'hidden',
  },
  messagesContainer: {
    flex: 1,
    overflowY: 'auto' as const, // Enable vertical scrolling
    padding: '20px',
    display: 'flex',
    flexDirection: 'column' as const,
    scrollBehavior: 'smooth' as const,
  },
  sampleMessages: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '20px',
    boxSizing: 'border-box' as const,
  },
  sampleMessagesTitle: {
    color: LightTheme.textColor,
    fontSize: '14px',
    marginBottom: '20px',
    textAlign: 'center' as const,
  },
  sampleMessageButton: {
    padding: '10px 15px',
    margin: '5px',
    backgroundColor: LightTheme.primaryBG,
    color: LightTheme.textColor,
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background-color 0.3s, color 0.3s',
    whiteSpace: 'nowrap' as const,
    maxWidth: '80%',
    textAlign: 'center' as const,
  },
  loadingMessage: {
    color: LightTheme.textColor,
    fontStyle: 'italic',
    // margin: '10px 0',
  },
  inputContainer: {
    display: 'flex',
    padding: '20px',
    justifyContent: 'center', // Center the input field
  },
  inputField: {
    flex: 1,
    maxWidth: '80%', // Make the input field narrower
    padding: '10px 15px', // Reduce vertical padding to make it shorter
    fontSize: '14px', // Slightly reduce font size
    backgroundColor: LightTheme.darkBG,
    color: LightTheme.textColor,
    border: `1px solid ${LightTheme.primaryAI}`,
    borderRight: 'none',
    borderRadius: '20px 0 0 20px',
    outline: 'none',
  },
  inputFieldDisabled: {
    backgroundColor: LightTheme.darkBG,
    color: LightTheme.textColor,
  },
  sendButton: {
    padding: '10px 15px', // Adjust padding to match input field height
    backgroundColor: LightTheme.darkBG,
    color: LightTheme.primaryBG,
    border: `1px solid ${LightTheme.primaryAI}`,
    borderLeft: 'none',
    borderRadius: '0 20px 20px 0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendButtonDisabled: {
    backgroundColor: LightTheme.darkBG,
    cursor: 'not-allowed',
  },
};

export default Chat;