import { HashRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Chat from './components/Chat';
import './App.css';
import merge from 'lodash.merge';
import iconSrc from './assets/transparent-100x100.png';
import xLogoSrc from './assets/xdotcom-white.png'; 

import '@rainbow-me/rainbowkit/styles.css';
import {
  RainbowKitProvider,
  ConnectButton,
  Theme,
  darkTheme
} from '@rainbow-me/rainbowkit';

import Product from './components/Product';
import Footer from './components/Footer';
import Privacy from './components/Privacy';
import TermsOfService from './components/TermsOfService'; 
import {LightTheme} from './styles/colors';
import Support from './components/Support';

// New ConnectButtonWrapper component
const ConnectButtonWrapper = () => {
  const location = useLocation();
  return location.pathname === '/chat' ? (
    <ConnectButton showBalance={false} chainStatus={'icon'} />
  ) : null;
};

const Header = () => {
  const location = useLocation();
  const isHome = location.pathname === '/';

  return (
    <header style={styles.header}>
      <div className="logo">
        <Link to="/">
          <img src={iconSrc} alt="Ditto Icon" className="logo-icon" style={styles.logoIcon} />
          <span style={styles.logoText}>Ditto</span>
        </Link>
      </div>
      <div className="header-right">
        {isHome && (
          <a href="https://x.com/dittowallet" target="_blank" rel="noopener noreferrer">
            <img src={xLogoSrc} alt="X (Twitter)" className="x-logo" />
          </a>
        )}
        <div className="connect-button-container">
          <ConnectButtonWrapper />
        </div>
      </div>
    </header>
  );
};

const App = () => {

  const simpleTheme = merge(darkTheme(), {
    shadows: {
      // connectButton: '#FFFFFF',
      // dialog: '#FFFFFF',
      // profileDetailsAction: '#FFFFFF',
      // selectedOption: '#FFFFFF',
      // selectedWallet: '#FFFFFF',
      // walletLogo: '#FFFFFF',
    },
    colors: {
      // accentColor: COLORS.lightPurple,
      // accentColorForeground: COLORS.lightPurple,
      // actionButtonBorder: COLORS.lightPurple,
      // actionButtonBorderMobile: COLORS.lightPurple,
      // actionButtonSecondaryBackground: COLORS.lightPurple,
      // closeButton: COLORS.lightPurple,
      // closeButtonBackground: COLORS.lightPurple,
      connectButtonBackground: LightTheme.primaryBG,
      connectButtonBackgroundError: LightTheme.primaryBG,
      connectButtonInnerBackground: LightTheme.primaryBG,
      // connectButtonText: COLORS.lightPurple,
      // connectButtonTextError: COLORS.lightPurple,
      connectionIndicator: LightTheme.primaryBG,
      // downloadBottomCardBackground: COLORS.lightPurple,
      // downloadTopCardBackground: COLORS.lightPurple,
      // error: COLORS.lightPurple,
      // generalBorder: COLORS.lightPurple,
      // generalBorderDim: COLORS.lightPurple,
      // menuItemBackground: COLORS.lightPurple,
      // modalBackdrop: COLORS.lightPurple,
      // modalBackground: COLORS.lightPurple,
      // modalBorder: COLORS.lightPurple,
      // modalText: COLORS.lightPurple,
      // modalTextDim: COLORS.lightPurple,
      // modalTextSecondary: COLORS.lightPurple,
      // profileAction: COLORS.lightPurple,
      // profileActionHover: COLORS.lightPurple,
      // profileForeground: COLORS.lightPurple,
      // selectedOptionBorder: COLORS.lightPurple,
      // standby: COLORS.lightPurple,
    }
  } as Theme);

  return (
    <Router>
      <RainbowKitProvider theme={simpleTheme}>
        <div className="App" style={styles.app}>
          <Header />
          <main className="landing-layout" style={styles.main}>
            <Routes>
              <Route path="/" element={<Product />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/privacy" element={<Privacy />} /> 
              <Route path="/terms-of-service" element={<TermsOfService />} /> 
              <Route path="/support" element={<Support />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </RainbowKitProvider>
    </Router>
  );
};

const styles = {
  app: {
    backgroundColor: LightTheme.primaryBG,
    // minHeight: '100vh',
    // display: 'flex',
    // flexDirection: 'column' as const,
  },
  header: {
    backgroundColor: 'transparent',
    color: LightTheme.primaryBG,
    padding: '20px',
  },
  logoIcon: {
    filter: `brightness(0) saturate(100%) invert(45%) sepia(57%) saturate(638%) hue-rotate(12deg) brightness(94%) contrast(92%)`,
  },
  logoText: {
    color: LightTheme.textColor,
  },
  main: {
    flex: 1,
    color: LightTheme.textColor,
  },
};

export default App;