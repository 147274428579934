import React from 'react';
import sendExample from '../assets/send-example-light.png';
import assetExample from '../assets/assets-example-light.png';
import {LightTheme} from '../styles/colors';

const Product: React.FC = () => {
  return (
    <div style={styles.product}>
      <div style={styles.leftColumn}>
        <p style={styles.heroTitle}>Wallet that understands you</p>
        <p style={styles.heroSubtitle}>
          Simply talk or snap a picture, and let your <span style={styles.aiAssistant}>AI assistant</span> handle the rest.
          <br />
          Send, swap, and bridge with ease — no more complex UI fumbling.
        </p>
        <div style={styles.ctaButtons}>
          {/* <Link to="/chat" style={styles.ctaButton}>Try Web Preview</Link> */}
          <button style={{...styles.ctaButton, ...styles.mutedButton}}>Mobile coming soon</button>
        </div>
      </div>
      <div style={styles.rightColumn}>
        <div style={styles.imageStack}>
          <img src={assetExample} alt="Asset Example" style={styles.exampleImage} />
          <img src={sendExample} alt="Send Example" style={styles.overlappingImage} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  product: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'flex-start',
    height: '80vh',
    boxSizing: 'border-box' as const,
    paddingTop: '1rem',
  },
  leftColumn: {
    flex: '1 1 50%',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    paddingRight: '20px',
    // maxWidth: '50%',
  },
  rightColumn: {
    flex: '1 1 50%',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    // maxWidth: '70%',
  },
  imageStack: {
    position: 'relative' as const,
    width: '100%',
    height: '80%',
    maxHeight: '80vh',
  },
  exampleImage: {
    position: 'absolute' as const,
    width: 'auto',
    height: '100%',
    maxWidth: '100%',
    objectFit: 'contain' as const,
    borderRadius: '8px',
    right: '50%',
    margin: "20px",
  },
  overlappingImage: {
    position: 'absolute' as const,
    width: 'auto',
    height: '100%',
    maxWidth: '100%',
    objectFit: 'contain' as const,
    borderRadius: '8px',
    zIndex: 1,
    left: '50%',
    margin: "20px",
  },
  heroTitle: {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: '3rem',
  },
  heroSubtitle: {
    fontSize: '1.2rem',
    marginBottom: '2rem',
    lineHeight: '2',
    color: LightTheme.gray,
  },
  aiAssistant: {
    color: LightTheme.primaryAI,
  },
  featureList: {
    listStyle: 'none',
    padding: 0,
    marginBottom: '2rem',
  },
  ctaButtons: {
    display: 'flex',
    gap: '1rem',
    marginTop: "3rem"
  },
  ctaButton: {
    padding: '10px 20px',
    fontSize: '1rem',
    backgroundColor: 'white',
    color: LightTheme.primaryBG,
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center' as const,
  },
  mutedButton: {
    backgroundColor: '#f0f0f0',
    color: '#888888',
    cursor: 'default',
  },
};

export default Product;

